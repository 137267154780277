import React from 'react';
import SiteContent from './injected/content/siteContent';
import SiteGenerator from './utils/structure/SiteGenerator';


const App: React.FC = () => {
  
  const zincContent: SiteContent = new SiteContent()
  return (
    <div className="App">

      <SiteGenerator content={zincContent}/>

    </div>
  );
}

export default App;
