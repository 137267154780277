import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactLibrary from '../common-contact/ContactLibrary';
import ContactType from '../common-contact/ContactType';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ContactFieldDropdownProps {
    disabled: boolean;
    value?: string;
    onUpdateValue(x: string): void;
}

const ContactFormDropdown: React.FC<ContactFieldDropdownProps> = (props) => {

    const title: string = "Where did you find us?";

    const prependElement = <div className="input-group-prepend">
        <span className="input-group-text"><FontAwesomeIcon icon={"ellipsis-h"} /></span>
    </div>

    const optionsArray = [];
    const optionsText = [
        "---  Please Select  ---",
        "Visited Shop",
        "Google Search",
        "Online Advertisement",
        "Trip Advisor Recommendations",
        "Recommendations from Friend & Family",
        "Recommendations from Work",
        "Direct Links from Affiliated Sites",
        "Others"
    ]

    const onSelect = (value: string) => {
        props.onUpdateValue(value);
    }

    for (let i: number = 0; i < optionsText.length; i ++) {
        const optText: string = optionsText[i];
        const optionElement = <option value={optText} key={i}>{optText}</option>
        optionsArray.push(optionElement);
    }

    return <div>
        <span><h6>{title}</h6></span>

        <div className="input-group mb-3">
            {prependElement}
            <select className="custom-select form-control" disabled={props.disabled} onChange={(e) => {onSelect(e.target.value)}}>
                {optionsArray}
            </select>
        </div>

    </div>
}

export default ContactFormDropdown;