import DisplayableElement from "../DisplayableElement";
import BlockTheme from "./BlockTheme";

class ContentBlockModel {

    public elements: Array<DisplayableElement> = [];
    public theme?: BlockTheme;
    public imagePath?: string;
    public ref?: any;
    public imagePositionX?: string;
    public imagePositionY?: string;
    public darkenPercent?: number;

    constructor(theme?: BlockTheme, imagePath?: string) {
        this.theme = theme;
        this.imagePath = imagePath;
    }

    public setTheme(theme: BlockTheme) {
        this.theme = theme;
        return this;
    }

    public setImagePositionX(value: string) {
        this.imagePositionX = value;
        return this;
    }

    public setImagePositionY(value: string) {
        this.imagePositionY = value;
        return this;
    }

    public setBackgroundImage(imagePath: string) {
        this.imagePath = imagePath;
        return this;
    }

    public setDarkenPercent(value: number) {
        this.darkenPercent = value;
        return this;
    }

    public add(element: DisplayableElement): ContentBlockModel {
        this.elements.push(element);
        return this;
    }
}

export default ContentBlockModel;