import React from 'react';
import DisplayableElement from '../../utils/structure/DisplayableElement';
import ContactFormModel from './ContactFormModel';
import ContactFormField, { FieldType } from './ContactFormField';
import ContactFormTextArea from './ContactFormTextArea';
import { invokePostApi } from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactFormDropdown from './ContactFormDropdown';
import isEmail from 'validator/lib/isEmail';


const DEFAULT_BUTTON_TEXT: string = "Submit";

export enum FormState {
    READY,
    PENDING,
    SUCCESS,
    FAILED,
    INVALID
}

class ContactForm extends DisplayableElement {

    private model: ContactFormModel;

    constructor(model: ContactFormModel) {
        super();
        this.model = model;
    }

    protected internalRender() {
        return <ContactFormJSX {...this.model} />;
    }
}

interface ContactFormClickProps {
    name?: string;
    email?: string;
    phoneNumber?: string;
    notes?: string;
    endpoint: string;
    reference: string;
    setFormState(x: FormState): void;
}

const onContactFormClickWithCaptcha = (props: ContactFormClickProps) => {
    grecaptcha.ready(function () {
        grecaptcha.execute('6Lf6za8ZAAAAAIWgZWJNxtF8klhvIjysKJNE8zyf', { action: 'submit' })
        .then(function (token) {
            onContactFormClick(props, token);
        });
    });
}

const onContactFormClick = (props: ContactFormClickProps, token: string) => {

    console.log(props);
    props.setFormState(FormState.PENDING);
    const payload = {
        name: props.name,
        email: props.email,
        phone: props.phoneNumber,
        notes: props.notes,
        token: token,
        is_dev_mode: false,
        fields: {
            reference: props.reference
        }
    };
    invokePostApi(props.endpoint, payload, x => onContactFormResponse(x, props), x => onContactFormError(x, props));
    // setTimeout(() => { props.setFormState(FormState.SUCCESS); onContactFormFakeSend() }, 1000);
}

const onContactFormResponse = (response: any, props: ContactFormClickProps) => {
    console.log(response);
    if (response.statusCode && response.statusCode === 200) {
        props.setFormState(FormState.SUCCESS);
    } else {
        props.setFormState(FormState.FAILED);
    }
}

const onContactFormError = (response: object, props: ContactFormClickProps) => {
    console.log(response);
    props.setFormState(FormState.FAILED);
}

const createContactFormField = (type: FieldType, isDisabled: boolean, hook: [string, (x: string) => void], hasFormBeenClicked: boolean): [string, JSX.Element, boolean] => {
    const [value, setValue] = hook;

    let isInvalidReason: string = "Please fill in a value";
    let isValid: boolean = value !== null && value !== undefined && value !== "";

    if (type === FieldType.Email && isValid) {

        const isEmailValid: boolean = isEmail(value);
        if (!isEmailValid) {
            isValid = false;
            isInvalidReason = "Please enter a valid email"
        }
    }

    const contactFormField = <ContactFormField
        type={type}
        onUpdateValue={setValue}
        value={value}
        disabled={isDisabled}
        isInvalid={!isValid && hasFormBeenClicked}
        isInvalidReason={isInvalidReason} />;

    return [value, contactFormField, isValid];
}

const createContactFormTextArea = (label: string, isDisabled: boolean, hook: [string, (x: string) => void]): [string, JSX.Element] => {
    const [value, setValue] = hook;
    const textAreaComponent: JSX.Element = <ContactFormTextArea title={label} setValue={setValue} value={value} disabled={isDisabled} />;
    return [value, textAreaComponent];
}

const createDropdown = (isDisabled: boolean, hook: [string, (x: string) => void]): [string, JSX.Element] => {
    const [value, setValue] = hook;
    const dropdownComponent: JSX.Element = <ContactFormDropdown onUpdateValue={setValue} value={value} disabled={isDisabled} />;
    return [value, dropdownComponent];
}

const createInfoBanner = (formState: FormState): JSX.Element => {

    if (formState === FormState.SUCCESS) {
        const message: string = "Success!";
        return <span className="text-success"><FontAwesomeIcon icon={"check"} style={{ marginRight: "0.6em" }} />{message}</span>;
    }

    if (formState === FormState.FAILED || formState === FormState.INVALID) {
        const message: string = formState === FormState.FAILED ? "Error occured" : "Invalid input";
        return <span className="text-danger"><FontAwesomeIcon icon={"times"} style={{ marginRight: "0.6em" }} />{message}</span>;
    }

    if (formState === FormState.PENDING) {
        const message: string = "Loading";
        return <div className="text-muted">
            <div className="spinner-border spinner-border-sm" role="status" style={{ marginRight: "0.6em" }}>
                <span className="sr-only" />
            </div>
            {message}
        </div>;
    }

    return <></>;
}

const ContactFormJSX: React.FC<ContactFormModel> = (props) => {

    // Pre-process.
    const notesFieldTitle: string = props.notesText === undefined ? "Notes" : props.notesText
    const displayButton: string = props.buttonText !== undefined ? props.buttonText : DEFAULT_BUTTON_TEXT;

    // React Hooks.
    const [formState, setFormState] = React.useState(FormState.READY);
    const isDisabled: boolean = formState !== FormState.READY && formState !== FormState.INVALID;
    const [isFormClicked, setIsFormClicked] = React.useState(false);

    // Fields
    const [userName, nameField, isNameValid] = createContactFormField(FieldType.Name, isDisabled, React.useState(""), isFormClicked);
    const [userEmail, emailField, isEmailValid] = createContactFormField(FieldType.Email, isDisabled, React.useState(""), isFormClicked);
    const [userPhone, phoneField, isPhoneValid] = createContactFormField(FieldType.PhoneNumber, isDisabled, React.useState(""), isFormClicked);
    const [userNotes, notesField] = createContactFormTextArea(notesFieldTitle, isDisabled, React.useState(""));
    const [userReference, referenceField] = createDropdown(isDisabled, React.useState(""));

    const formProps: ContactFormClickProps = {
        name: userName,
        email: userEmail,
        phoneNumber: userPhone,
        notes: userNotes,
        endpoint: props.contactFormApi,
        setFormState: setFormState,
        reference: userReference
    }

    const onSubmit = (e: any) => {

        e.preventDefault();
        const isFormValid: boolean = isNameValid && isEmailValid && isPhoneValid;
        setIsFormClicked(true);

        if (isFormValid) {
            onContactFormClickWithCaptcha(formProps);
        } else {
            setFormState(FormState.INVALID);
        }
    }

    let infoBanner: JSX.Element = createInfoBanner(formState);
    const titleElement: JSX.Element | null = props.title ? <h2>{props.title}</h2> : null;
    const subtitleElement: JSX.Element | null = props.subtitle ? <h5 className="card-title">{props.subtitle}</h5> : null;
    const bodyElement: JSX.Element | null = props.body ? <p className="card-text">{props.body}</p> : null;

    // Add elements which are required by the model.
    const displayedNameField = props.requireName === true ? nameField : null;
    const displayedEmailField = props.requireEmail === true ? emailField : null;
    const displayedPhoneField = props.requirePhone === true ? phoneField : null;
    const displayedNotesField = props.requireNotes === true ? notesField : null;

    const recaptchaText = <div className="text-muted" style={{fontSize: "0.9rem", marginTop: "0.5rem"}}>
        This site is protected by reCAPTCHA and the Google 
        <a href="https://policies.google.com/privacy"> Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>

    return <form onSubmit={onSubmit}>
        <div className="card">
            <div className="card-body">

                {titleElement}
                {subtitleElement}
                {bodyElement}
                {displayedNameField}
                {displayedEmailField}
                {displayedPhoneField}
                {referenceField}
                {displayedNotesField}
                {recaptchaText}

                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>{infoBanner}</div>
                    <button style={{ minWidth: "120px" }} className="btn btn-primary" type="submit" disabled={isDisabled}>{displayButton}</button>
                </div>
            </div>
        </div>
    </form>
}

export default ContactForm;
