// Component Imports
import SiteContentInterface from "../../utils/structure/SiteContentInterface";
import ContentBlockModel from "../../utils/structure/ContentBlock/ContentBlockModel";
import Card from "../../components/card/Card";
import OpeningHours from "../../components/opening-hours/OpeningHoursElement";
import Gallery from "../../components/gallery/Gallery";
import Location from "../../components/location/Location";

// Import Text Content.
import sampleSection from "./text/sampleSection.md";

// Media Imports
import panoImage from "../images/pano.jpeg";
import gallery1 from "../images/gallery_1.png";
import gallery2 from "../images/gallery_2.png";
import gallery3 from "../images/gallery_3.png";

import Product1 from "../images/product1.jpeg";
import Product2 from "../images/product2.jpeg";
import Product3 from "../images/product3.jpeg";
import Product4 from "../images/product4.jpeg";
import Product5 from "../images/product5.jpeg";
import Product6 from "../images/product6.jpeg";
import Product7 from "../images/product7.jpeg";
import Product8 from "../images/product8.jpeg";


import mapImage from "../images/location_map.png";

// Import Font Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faUser, faTimes, faInfo, faMapMarkedAlt, faCheck, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, } from '@fortawesome/free-regular-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import TextSection from "../../components/text-section/TextSection";
import DisplayableElement from "../../utils/structure/DisplayableElement";
import ContactLibrary from "../../components/common-contact/ContactLibrary";
import ContactType from "../../components/common-contact/ContactType";
import ContactEntry from "../../components/common-contact/ContactEntry";
import BlockTheme from "../../utils/structure/ContentBlock/BlockTheme";
import ContactForm from "../../components/contact-form/ContactForm";
import { useRef } from "react";


class SiteContent extends SiteContentInterface {
    constructor () {
        super();
        
        this.registerIconLibrary();
        this.populateApi();
        this.populateContact();
        this.populateHeader();
        this.populateFooter();
        this.addCustomContent();
    }

    // ==========================================================================================
    // Custom Content Content.
    // ==========================================================================================

    private addCustomContent = (): void => {

        const myRef = useRef(null);

        this.addHeroBlock(myRef);
        this.addBlockWithFeatures();
        this.addBlockWithCards();
        this.addBlockWithContactAndLocation(myRef);
        // this.addBlockGallery();
    }

    private populateHeader = (): void => {
        this.setHeaderModel({
            title: "WildFlower Thai Massage",
            subtitle: "Traditional Thai Massage, South of the Swan River",
            contactTypesToShow: [ContactType.PHONE, ContactType.EMAIL],
            theme: BlockTheme.FEATURE,
            blocks: []
        })
    }

    private populateFooter = (): void => {
        this.setFooterModel({
            height: 32,
            contactModel: this.getContactModel(),
            contactTextToShow: [ContactType.PHONE, ContactType.EMAIL],
            contactIconsToShow: [ContactType.FACEBOOK],
            theme: BlockTheme.INVERTED
        })
    }
    
    private addHeroBlock = (ref?: any): void => {
        const textElement = new TextSection({body: "Loading", markdownPath: sampleSection, centered: true, minHeight: 300, 
        callToActionLabel: "Book Now", callToActionLink: "#", callToActionRef: ref});
        const blockModel = this.addElementsAsNewBlock(textElement);

        blockModel
            .setImagePositionX("80%")
            .setBackgroundImage(panoImage)
            .setDarkenPercent(0.3)
            .setTheme(BlockTheme.INVERTED);
    }

    private withPrice(body: string, duration: string, price: string) {
        const priceTag = `\n\n###### **${price}** — ${duration}`;
        return body + priceTag;
    }

    private newProductCard(title: string, body: string, image: string, prices: Array<Array<string>>) {
        body += "\n\n --- \n\n";
        for (let i: number = 0; i < prices.length; i++) {
            body = this.withPrice(body, prices[i][0], prices[i][1]);
        }

        const card = new Card({
            title: title, 
            body: body,
            imagePath: image,
        });

        return card;
    }

    private addBlockWithCards = (): void => {

        const card1 = this.newProductCard(
            "Traditional Thai Massage",
            "Feeling tired? Invigorate yourself with our WildFlower Thai Massage; A traditional massage of intensive rhythmic pressing and movement.",
            Product1,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card2 = this.newProductCard(
            "Thai Classic Massage & Stretching",
            "Enjoy an ancient massage practice that targets deep muscle pains and body-wide stiffness.",
            Product2,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card3 = this.newProductCard(
            "Aromatherapy",
            "Stimulate your core senses with aromatic essential oils, lotions and herbs, a heavenly experience!",
            Product3,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card4 = this.newProductCard(
            "Foot Massage",
            "Been on your feet all day? Our foot massage will improve circulation, and release tensed muscles.",
            Product4,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card5 = this.newProductCard(
            "Hot Stone Massage",
            "Hot Stones massages greatly encourage blood circulation by expanding blood vessels, and as a form of thermotherapy, will relieve body-wide tensions.",
            Product5,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card6 = this.newProductCard(
            "Body Scrub",
            "Our massage therapists gently apply exfoliating agents onto your body, deeply cleansing your skin and pores, and leaving you feeling refreshed and absolutely radiating.",
            Product6,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card7 = this.newProductCard(
            "Deep Tissue Massage",
            "A very strong Thai massage that targets deep persistent sores and muscle aches.",
            Product7,
            [["60 mins", "$75"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const card8 = this.newProductCard(
            "Thai Oil & Body-Walking Massage",
            "The broad pressure is less intensive than deep-tissue, yet great at reducing inflammations.",
            Product8,
            [["60 mins", "$80"], ["90 mins", "$120"], ["120 mins", "$150"]]
        )

        const openingHours = new OpeningHours({
            showPublicHolidayMessage: true,
            slots: [
                {day: "Monday - Saturday", detail: "9:30 AM to 6 PM", isOpen: true},
                {day: "Sunday", detail: "Closed", isOpen: false},
            ]
        });

        this.addElementsAsNewBlock(card1, card2, card3).setTheme(BlockTheme.BASIC);
        this.addElementsAsNewBlock(card7,card8, card4).setTheme(BlockTheme.BASIC);
        this.addElementsAsNewBlock(card5, card6).setTheme(BlockTheme.BASIC);
        this.addElementsAsNewBlock(openingHours).setTheme(BlockTheme.BASIC);

    }

    private addBlockWithContactAndLocation = (ref: any): void => {

        const contactElement = new ContactForm({title: "Contact Us", contactFormApi: this.getEndpoint("contact"), 
        requireName: true, requireEmail: true, requirePhone: true, requireNotes: true});
        
        const mapLink: string | undefined = this.getContactModel().contactMap.get(ContactType.ADDRESS)?.link;
        const locationElement = new Location({
            title: "Our Location",
            mapAddress: mapLink ? mapLink : "",
            contactTypesToShow: [ContactType.ADDRESS, ContactType.PHONE, ContactType.EMAIL], 
            contactModel: this.getContactModel(),
            mapImage: mapImage});
            
        this.createNextBlock(BlockTheme.BASIC, ref)
        .add(locationElement)
        .add(contactElement);
    }

    private addBlockWithFeatures = (): void => {

    }

    // private addBlockGallery = (): void => {
    //     const galleryElement = new Gallery({
    //         images: [],
    //         imageColMd: 3
    //     });

    //     this.addElementsAsNewBlock(galleryElement).setTheme(BlockTheme.FEATURE);
    // }

    // ==========================================================================================
    // Common Content.
    // ==========================================================================================

    private populateContact = (): void => {

        const contactMap: Map<ContactType, ContactEntry> = new Map<ContactType, ContactEntry>();
        contactMap.set(ContactType.EMAIL, {body: "wildflowerthaimassage@gmail.com", link: "wildflowerthaimassage@gmail.com"});
        contactMap.set(ContactType.PHONE, {body: "0467 429 241 (Amy)", link: "tel: 0467 429 241 (Amy"});
        contactMap.set(ContactType.FACEBOOK, {body: "Facebook Address", link: "https://www.facebook.com/WildFlower-Thai-Massage-101551898915097"});
        contactMap.set(ContactType.ADDRESS, {
            body: "We currently only provide Mobile massages (home calls), starting at $90 minimum, within 10KM of Mosman Park. Please call us for more details.", 
            link: "Mosman Park"});

        this.setContactModel({
            name: "WildFlower Thai Massage (ABN: 040 324 372 270)",
            contactMap: contactMap
        });
    }

    private populateApi = (): void => {
        this.setApiEndpoint("https://apix.wildflowerthaimassage.com.au/");
        this.setGoogleMapApiKey(undefined);
    }

    private registerIconLibrary = (): void => {

        library.add(faPhone, faEnvelope, faFacebookSquare, faUser, faTimes, faMapMarkedAlt);
        library.add(faInfo);
        library.add(faCheck);
        library.add(faEllipsisH);

        // Set the icons that we need for our contact library.
        const contactLibrary: ContactLibrary = ContactLibrary.getInstance();
        contactLibrary.setLabelAndIcon(ContactType.NAME, "Name", faUser);
        contactLibrary.setLabelAndIcon(ContactType.ADDRESS, "Address", faMapMarkedAlt);
        contactLibrary.setLabelAndIcon(ContactType.PHONE, "Phone", faPhone);
        contactLibrary.setLabelAndIcon(ContactType.EMAIL, "Email", faEnvelope);
        contactLibrary.setLabelAndIcon(ContactType.FACEBOOK, "Facebook", faFacebookSquare);
    }

    // ==========================================================================================
    // Utility.
    // ==========================================================================================

    private createNextBlock = (theme?: BlockTheme, ref?: any): ContentBlockModel => {
        const block = new ContentBlockModel(theme);
        block.ref = ref;
        this.getIndexPage().add(block);
        return block;
    }

    private addElementsAsNewBlock = (...elements: DisplayableElement[]): ContentBlockModel => {
        const block = this.createNextBlock();
        elements.forEach(e => block.add(e));
        return block;
    }
}

export default SiteContent

