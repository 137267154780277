import React from 'react';


interface CallToActionButtonProps {
    label?: string;
    link?: string;
    extraClass?: string;
    callToActionRef?: any;
}

const CallToActionButton: React.FC<CallToActionButtonProps> = (props) => {

    if (props.link) {

        const label: string = props.label ? props.label : "Click Here";
        const extraClass: string = props.extraClass ? props.extraClass : "";
        let buttonElement;

        if (props.callToActionRef !== undefined) {
            const executeScroll = () => props.callToActionRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center'
            }); 
            
            buttonElement = <button 
                className={"btn btn-primary " + extraClass}
                onClick={executeScroll}>{label}
            </button>
        } else {
            buttonElement = <a 
                className={"btn btn-primary " + extraClass}
                href={props.link}>{label}
            </a>
        }

        return <p>{buttonElement}</p>;

    } else {
        return <></>;
    }
}

export default CallToActionButton;