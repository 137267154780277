import React from 'react';
import DisplayableElement from '../DisplayableElement';
import BlockTheme from './BlockTheme';


interface ContentBlockProps {
    elements: DisplayableElement[];
    height?: number;
    color?: string;
    classOverride?: string;
    theme?: BlockTheme;
    pageRef?: any;

    backgroundImagePath?: string;
    backgroundPositionX?: string;
    backgroundPositionY?: string;
    darkenPercent?: number;
}

const getElementSize = (numberOfElements: number) => {

    const columnClasses: Array<string> = ["col-sm-12"];

    switch (numberOfElements) {

        case 2:
            columnClasses.push("col-md-6");
            break;

        case 3:
            columnClasses.push("col-md-4");
            break;

        case 4:
            columnClasses.push("col-md-3");
            break;
    }

    return columnClasses.join(" ")

}

const ContentBlock: React.FC<ContentBlockProps> = (props) => {

    const blockStyle = {
        width: "100%", 
        height: "auto",
        minHeight: "auto"
    };

    const themeStyle: {[key: string]: string | undefined} = {
        backgroundImage: "none",
        backgroundColor: undefined,
        backgroundBlendMode: undefined,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: props.backgroundPositionX,
        backgroundPositionY: props.backgroundPositionY
    }

    const backgroundDarkenOpacity: number = props.darkenPercent ? props.darkenPercent : 0;
    
    if (props.backgroundImagePath) {
        themeStyle.backgroundImage = `url(${props.backgroundImagePath})`;
        themeStyle.backgroundColor = `rgba(0, 0, 0, ${backgroundDarkenOpacity})`;
        themeStyle.backgroundBlendMode = "darken";
    }

    if (props.height !== undefined) {
        blockStyle.minHeight = `${props.height}px`;
    }

    let blockClass: string = "content-block dev block-style";
    if (props.classOverride) {
        blockClass += " " + props.classOverride;
    }

    const renderedElements: Array<JSX.Element> = [];
    const elementSize: string = getElementSize(props.elements.length);

    for (let i: number = 0; i < props.elements.length; i++) {
        const renderedElement: JSX.Element = props.elements[i].render();
        const renderedContainer: JSX.Element = <div className={"display-element " + elementSize} key={`block${i}`}>{renderedElement}</div>
        renderedElements.push(renderedContainer);
    }

    const theme: string = props.theme ? props.theme : BlockTheme.BASIC;
    
    return <div className={blockClass} style={blockStyle} ref={props.pageRef}>
        <div className={theme} style={themeStyle}>
            <div className="container dev content-holder">
                <div className="row" style={{height: "100%"}}>{renderedElements}</div>
            </div>
        </div>
    </div>
}

export default ContentBlock;